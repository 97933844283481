module.exports = {
  home: {
    text1: '欢迎使用',
    text2: '本次登录IP',
    text3: '本次登录时间',
    text4: '上次登录IP',
    text5: '上次登录时间',
    text6: '最近一周数据统计',
    text7: '用户注册数',
    text8: '订单数',
    text9: '本后台系统由上海米因大数据科技开发团队 技术支持',
  },
  admin: {
    t1: '菜单列表',
    t2: '新增菜单',
    t3: '修改菜单',
    t4: '菜单名称',
    t5: '所属一级菜单',
    t6: '图标',
    t7: '路由',
    t8: '排序',
    t9: '是否显示',
    t10: '编号',
    t11: '操作',
    t12: '英文名称',
  },
  goods: {
    t1: '商品管理',
    t2: '添加商品',
    t3: '编号',
    t4: '商品图',
    t5: '名称',
    t6: '价格',
    t7: '市场价格',
    t8: '验血价格',
    t9: '状态',
    t10: '操作日期',
    t11: '操作',
    t12: '排序',
    t13: '历史图库',
    t14: '添加',
    t15: '修改',
    t16: '请输入',
    t17: '提交',
    t18: '取消',
    t19: '商品',
    t20: '图片',
    t21: '请输入名称',
    t22: '请输入价格',
    t23: '请输入市场价格',
    t24: '代码',
  },
  open: {
    a1: '该套餐仅适用于年龄在20~90周岁之间人群!',
    a2: '该套餐仅适用于年龄在40~80周岁之间人群!',

    t1: '个人信息',
    t2: '问卷信息',
    t3: '体型信息',
    t4: '生化信息',
    t5: '手机号',
    t6: '企业用户ID',
    t7: '用户性别',
    t8: '出生日期',
    t9: '报告单位',
    t10: '体检日期',
    t11: '套餐内容',
    t12: '上一步',
    t13: '下一步',
    t14: '男',
    t15: '女',
    t16: '一、基本资料',
    t17: '1.客户的居住地',
    t18: '2.客户的喝酒情况（单选）？',
    t19: '3.客户是否吸烟（单选）？',
    t20: '4.客户的用餐习惯（单选）？',
    t21: '5.客户是否是全日素食者（单选）？',
    t22: '6.客户的运动习惯（单选）？',
    t23: '7.客户是否有睡眠障碍（单选）？',
    t24: '8.客户是否患有以下疾病？',
    t25: '9.客户是否做过下列手术？',
    t26: '10.客户的直系亲属有下列疾病吗？',
    t27: '二、个人病史',
    t28: '三、个人手术史',
    t29: '四、家族病史',
    t30: '身高',
    t31: '体重',
    t311: '胸围',
    t32: '腰围',
    t33: '臀围',
    t34: '体脂率',
    t35: '若不填体脂率,则根据您的信息自动生成',
    t36: '血压检查',
    t37: '血常规检查',
    t38: '血糖检查',
    t39: '血脂检查',
    t40: '肝功能检查',
    t41: '肾功能检查',
    t42: '收缩压(SBP)',
    t43: '舒张压(DBP)',
    t44: '白细胞(WBC)',
    t45: '血红蛋白(HGB)',
    t46: '血小板(PLT)',
    t47: '空腹血糖(FG)',
    t48: '糖化血红蛋白(HBA1C)',
    t49: '总胆固醇(CHOL)',
    t50: '低密度脂蛋白(LDLC)',
    t51: '高密度脂蛋白(HDLC)',
    t52: '甘油三酯(TG)',
    t53: '谷草转氨酶（GOT/AST）',
    t54: '谷丙转氨酶（GPT/ALT）',
    t55: '碱性磷酸酶(ALP)',
    t56: '血清白蛋白(ALB)',
    t57: '肌酐(CRE)',
    t58: '尿酸(UA)',
    t59: '肾小球滤过率(EGFR)',
    t60: '选填',
    t61: '确认提交',
    t62: '妈妈信息',
    t63: '宝宝信息',
    t64: '骨折问卷',

    q1: '不喝',
    q2: '天天喝',
    q3: '经常喝',
    q4: '偶尔喝',
    q5: '不吸',
    q6: '天天吸',
    q7: '经常吸',
    q8: '偶尔吸',
    q9: '定时定量',
    q10: '不定时不定量',
    q11: '定时不定量',
    q12: '不定时但定量',
    q13: '不是',
    q14: '是',
    q15: '没有或每周少于一小时',
    q16: '每周运动1~2小时',
    q17: '每周运动3~4小时',
    q18: '每周运动5~6小时',
    q19: '每周运动7小时以上',
    q20: '没有',
    q21: '非常严重',
    q22: '经常',
    q23: '偶尔',
    q24: '否',
    q25: '是，请选择服药情况（多选）',
    q26: '疾病名称',
    q27: '不服药',
    q28: '偶尔服药',
    q29: '经常服药',
    q30: '是，请选择（多选）',

    f1: '4.客户曾经是否骨折（单选）？',
    f2: '5.客户父母是否曾经髋部骨折（单选）？',
    f3: '6.客户的日常生活与事务决定处理与认知能力？（单选）？',
    f4: '7.股骨颈骨密度（BMD）？',
    f5: '8.客户是否患过以下疾病？',
    f6: '9.客户是否需要医护服务？',

    f7: '不知道',
    f8: '自主决定，一直且合理',
    f9: '部分自主，遇到新状况会有困难',
    f10: '多数情况无法自主、或决定欠佳、或需提示',
    f11: '不知道',
    f12: '请填写客户曾经骨折次数',
    f13: '请输入骨密度',
    f14: '请选择客户是否患有以下疾病',
    f15: '请选择客户的居住地',
    f16: '请选择客户的喝酒情况',
    f17: '请选择客户是否吸烟',
    f18: '请选择客户曾经是否存骨折',
    f19: '请选择客户父母是否曾经髋部骨折',
    f20: '请选择客户生活自主认知能力',
    f21: '请填写客户骨密度值',
    f22: '请填写客户曾经骨折次数',

    f23: '没有',
    f24: '有',

    f25: '请输入手机号',
    f26: '请输入企业用户ID',
    f27: '手机号与企业用户ID至少填写一项',
    f28: '请选择',
    f29: '请选择出生日期',
    f30: '请选择体检日期',
    f31: '体检日期大于出生日期且不能大于当前时间',
    f32: '请选择套餐',
    f33: '请选择开通套餐',
    f34: '请选择客户的用餐习惯',
    f35: '请选择客户是否为全日素食者',
    f36: '请选择客户的运动习惯',
    f37: '请选择客户是否存在睡眠障碍',
    f38: '请选择服药情况',
    f39: '请填写您的身高',
    f40: '请填写您的体重',
    f41: '请输入身高',
    f42: '请输入体重',
    f43: '若不填腰围,则根据您的信息自动生成',
    f44: '若不填臀围,则根据您的信息自动生成',
    f45: '若不填体脂率,则根据您的信息自动生成',
    f46: '不能为空',
    f47: '请输入（选填）',
    f48: '请输入白细胞',
    f49: '请选择客户性别',
    f50: '请输入腰围',
    f501: '请输入腰围(选填)',
    f51: '请输入臀围',
    f511: '请输入臀围(选填)',
    f52: '请输入胸围',
    f53: '请填写您的胸围',

    f54: '身高为130~200',
    f55: '体重为30~160',
    f56: '胸围为40~165',
    f57: '腰围为40~150',
    f58: '臀围为60~165',
    f59: '体脂率为3~75',

    f60: '确认输入信息',
    f61: '您填写的结果可能存在较大偏差，请您仔细核对该数值和所用单位是否正确并与您的报告一致。<br/>若您已经核对确认，并坚持以该结果进行提交和评估，我们可能会根据大数据算法对该结果进行一定程度上的修正和处理，以提供更合理的大数据评估结果和建议。',

    m1: '产前的体型信息',
    m2: '产后的体型信息',
    m3: '孕周数',
    m4: '妈妈信息',
    m5: '产前两年内是患过下列疾病？(多选)',
    m6: '请填写您的孕周数',
    m7: '妈妈的生育年龄',
    m8: '宝宝的出生孕周',
    m9: '宝宝的出生体重',
    m10: '宝宝的性别',
    m11: 'APGAR分数',
    m12: 'APGAR分数——即新生儿评分(第一分钟)为0~10',
    m13: '请输入宝宝正确的的出生孕周',
    m14: '请输入妈妈正确的生育年龄',
    m15: '请输入宝宝正确的出生体重',
    m16: '请选择入宝宝性别',
    m17: '请输入妈妈的生育年龄',
    m18: '请输入宝宝的出生孕周',
    m19: '请输入宝宝的出生体重',
    m20: '请输入APGAR分数——即新生儿评分(第一分钟)',
    m21: '宝宝信息',
  },
  table: {
    t1: '编号',
    t2: '手机号/企业用户ID',
    t3: '套餐内容',
    t4: '性别',
    t5: '出生日期',
    t6: '体检时间',
    t7: '状态',
    t8: '次数',
    t9: '操作',
    t10: '操作人',
    t11: '操作时间',
    t12: '开始日期',
    t13: '结束日期',
    t14: '全部套餐',
    t15: '开通日期',
    t16: '企业名称',
    t17: '付费方式',
    t18: '使用次数',
    t19: '剩余次数',
    t20: '查看详情',
    t21: '姓名',
    t22: '免费',
    t23: '身份证号',
    t24: '套餐名称',
    t25: '日期',
    t26: '后付费',
    t27: '预付费',
    t28: '消息',
    t29: '设置',
    t30: '退出',
    t31: '侧边栏皮肤',
    t32: '浅色',
    t33: '深色',
    t34: '总',
    t35: '共',

    t36: '已完成',
    t37: '未完成',
    t38: '修改',
    t39: '查询',
    t40: '重置',
    t41: '查看',
    t42: '下载',

    t43: '请输入手机号/企业用户ID',

    t44: '开通',
    t45: '次',

    t46: '语言',

    t47: '修改密码',

    t48: '账号名称',
    t49: '原密码',
    t50: '新密码',
    t51: '确认新密码',

    t52: '暂无数据',
  },

  // 客户居住地
  areaList: [
    { text: '江苏省', code: '3200' },
    { text: '上海市', code: '3100' },
    { text: '北京市', code: '1100' },
    { text: '天津市', code: '1200' },
    { text: '浙江省', code: '3300' },
    { text: '广东省', code: '4400' },
    { text: '辽宁省', code: '2100' },
    { text: '山东省', code: '3700' },
    { text: '重庆市', code: '5000' },
    { text: '福建省', code: '3500' },
    { text: '海南省', code: '4600' },
    { text: '湖北省', code: '4200' },
    { text: '湖南省', code: '4300' },
    { text: '陕西省', code: '6100' },
    { text: '河南省', code: '4100' },
    { text: '黑龙江省', code: '2300' },
    { text: '青海省', code: '6300' },
    { text: '云南省', code: '5300' },
    { text: '安徽省', code: '3400' },
    { text: '四川省', code: '5100' },
    { text: '贵州省', code: '5200' },
    { text: '甘肃省', code: '6200' },
    { text: '江西省', code: '3600' },
    { text: '吉林省', code: '2200' },
    { text: '山西省', code: '1400' },
    { text: '河北省', code: '1300' },
    { text: '宁夏回族自治区', code: '6400' },
    { text: '广西壮族自治区', code: '4500' },
    { text: '新疆维吾尔自治区', code: '6500' },
    { text: '内蒙古自治区', code: '1500' },
    { text: '西藏自治区', code: '5400' },
    { text: '中国台湾', code: '0000' },
    { text: '中国香港', code: '4401' },
  ],

  diseases: {
    d1: '高血压',
    d2: '高血脂',
    d3: '糖尿病',
    d4: '慢性肝病',
    d5: '慢性肾脏病',
    d6: '癌症',
    d7: '肝硬化',
    d8: '慢性阻塞性肺炎',
    d9: '关节炎',
    d10: '心脏病',
    d11: '脑中风',
    d12: '胃肠溃疡',
    d13: '睡眠障碍',
    d14: '贫血',
    d15: '疼痛',
    d16: '哮喘',
    d17: '痛风',
    d18: '痔疮',
    d19: '甲状腺疾病',
  },
  oper: {
    d1: '头或脑部',
    d2: '眼鼻耳',
    d3: '口腔、食道',
    d4: '胸部(心、肺等)',
    d5: '腹部(胃、肝、胆等)',
    d6: '关节与骨',
    d7: '妇科手术',
    d8: '泌尿系统',
  },
  famdis: {
    d1: '癌症',
    d2: '脑中风',
    d3: '心肌梗塞',
    d4: '肝硬化',
    d5: '老年失智',
    d6: '慢性阻塞性肺炎',
    d7: '结核病',
    d8: '先天畸形',
  },

  goodsList: [
    {
      id: '5',
      name: 'AI智慧健康',
      goods_id: '5',
      type: 'AIZH',
      en_name: '[AI]Smart Health',
    },
    {
      id: '6',
      name: 'AI银发骨健康套餐',
      goods_id: '6',
      type: 'AIFB',
      en_name: '[AI]Smart Fracture-Risk',
    },
    {
      id: '7',
      name: '尊贵白金套餐',
      goods_id: '7',
      type: 'AIBG',
      en_name: '[AI]VIP Health Risk Examination',
    },
    {
      id: '3',
      name: '馨享母婴套餐',
      goods_id: '3',
      type: 'AIMB',
      en_name: '[AI]Postpartum Risk (Mother & Newborn)',
    },
    {
      id: '9',
      name: 'M22四高AI分析',
      goods_id: '9',
      type: 'M22',
      en_name: '[AI]4-Hypers Analysis',
    },
  ],

  aijk: {
    a1: '您属于',
    a2: '健康人群',
    a3: '亚健康人群',
    a4: '医疗需求者',
    a5: '身高',
    a6: '体重',
    a7: '腰围',
    a8: '臀围',
    a9: '体脂率',
    a10: '九十岁概率',
    a11: '百岁概率',
    a12: '根据健康大数据的评价，您的健康状况属于',
    a13: '健康',
    a14: '亚健康',
    a15: '医疗需求',
    a16: '您的健康分为',
    a17: '分数',
    a18: '一、体型评估',
    a19: '体型是影响健康的重要指标之一。',
    a20: '不健康的生活方式会直接影响体型，同时带来健康风险。',
    a21: '消瘦',
    a22: '精实',
    a23: '健美',
    a24: '苗条',
    a25: '适中',
    a26: '壮硕',
    a27: '隐性肥胖',
    a28: '肥胖',
    a29: '您的体型属于',
    a30: '二、体重及体型改善方案（12周）',
    a31: '每个人都有适合自己的最佳体型，过胖或过瘦都不好，请注意体型的改善和维持。',
    a32: '当前体重',
    a33: '建议体重',
    a34: '当前腰臀比',
    a35: '建议腰臀比',
    a36: '三、医疗费用的预测',
    a37: '基于您的总体健康状况和疾病风险，预测您的未来医疗费用。',
    a38: '未来一年医疗费用预测',
    a39: '四、未来疾病风险预测—急重症风险',
    a40: '长期患病往往会导致各种危重症急性发作，伴随健康状况的迅速恶化，甚至危及生命。',
    a41: '急重症会带来数十万的医疗花费，并对身体造成巨大的、不可逆的伤害。',
    a42: '这是您患病风险概率最高的几种急危重症。',
    a43: '同族群（PLM，People Like Me）:与您相似的人群--与您同性别同年龄段的人群',
    a44: '风险排序',
    a45: '四、未来疾病风险预测—系统疾病风险',
    a46: '系统患病风险概率包含了从微小疾病到重大疾病的所有风险概率。',
    a47: '人体共有九大生理系统，这是您患病风险概率最高的五个系统。',
    a48: '四、未来疾病风险预测—慢病风险',
    a49: '亚健康状态作为人体的第三状态，可以向健康和疾病两个方向转化。',
    a50: '若人体长期处于亚健康状态，没有及时进行健康管理和疾病预防，将极有可能诱发一些慢性疾病。',
    a51: '这是您患病风险概率最高的几种慢性疾病。',
    a52: '偏低',
    a53: '适中',
    a54: '偏高',
    a55: '主要症状',

    d1: '下载报告',
    d2: '米因健康AI体检官方平台',
    d3: '我们建议您通过在线浏览获得更直观的图表分析和更好的浏览体验。',
    d4: '体检日期',
    d5: '电话',
    d6: '健康AI评估报告',
    d7: '米因AI智慧医疗',
    d8: 'AI体检分析与预测报告', //63
    d9: '医疗大数据+人工智能',
    d10: '是利用海量的数据对健康、疾病与死亡做出的不同的风险预测与分析，这些医疗大数据是经过持续超过2500万华人生、老、病、死的连续25年以上的健康和医疗记录组成；目的是评估人整体健康现况，预测未来健康可能演化的趋势，以提供「治未病」的改善方案，让每个人拥抱健康、远离疾病，是现今智能社区发展的大趋势。',
    d11: '米因智慧医疗为您评估健康风险',
    d12: '愿健康常伴您左右',
    d13: '本AI分析报告仅作为健康风险提示与健康管理的参考，并非由专业机构或医生出具的临床诊断结果，如果您有任何疑问与需要，请寻求专业医护机构与人员的帮助。',
    d14: '基本信息',
    d15: '性别',
    d16: '年龄',
    d17: '问卷信息',
    d18: '居住地',
    d19: '是否喝酒',
    d20: '是否吸烟',
    d21: '用餐习惯',
    d22: '是否是全日素食者',
    d23: '运动习惯',
    d24: '是否有睡眠障碍',
    d25: '患有的疾病',
    d26: '患病而不服用药物的疾病',
    d27: '患病而偶尔服用药物的疾病',
    d28: '患病而经常服用药物的疾病',
    d29: '做过的手术',
    d30: '直系亲属患有的疾病',
    d31: '体脂率由低',
    d32: 'BMI由低',
    d33: '高',
  },

  menus: localStorage.getItem('menusList')
    ? JSON.parse(localStorage.getItem('menusList'))
    : [],
}
