import Vue from 'vue'
import Vuex from 'vuex'

import router from '@/router'
 
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  isLogin: localStorage.getItem('access_token')?true:false ,// 是否登录
	  
	  menus: [],// 权限菜单
  },
  mutations: {
	  // 存储权限菜单
	  addMenus(state, list) {
		  state.menus = list;
	  },
	  
	  //切换登录状态
	  checkLogin(state, isLoging) {
		  state.isLogin = isLoging;
	  },
	  
	  setAdminId(state, id){
		  state.adminId = id
	  }
  },
  actions: {
	  // 登录
	  login({ commit }, token) {
		  localStorage.setItem('access_token', token);
		  commit('checkLogin', true);
	  },
	  // 登出
	  logout({ commit }) {
		  localStorage.clear();
		  sessionStorage.clear();
		  commit('checkLogin', false);
		  
		  // 清除权限菜单
		  commit('addMenus', []);
		  // 重置路由
		  // router.$resetRouter();
		  
		  // 跳转至登录页
		  router.replace('/login');
		  // 刷新页面(重置路由新建替换matcher无效,后续研究)
		  location.reload();
	  }
  },
  modules: {
  }
})
