// 函数调取弹窗

import Vue from 'vue'
import alert from './index.vue'

let AlertConstructor = Vue.extend(alert);// 返回一个“扩展实例构造器” 

let AlertModal = (o) => {
	let alertDom = new AlertConstructor({
		el: document.createElement('div')//将Alert组件挂载到新创建的div上 
	});
	document.body.appendChild(alertDom.$el);//把Alert组件的dom添加到body里 
	
	// 类型
	alertDom.type = o.type;
	// 标题
	alertDom.title = o.title || (o.type == 'success'?'提交成功':(o.type == 'fail'?'提交失败':'标题'));
	// 单条内容
	alertDom.content = o.content;
	// callback
	alertDom.callback = o.callback;
	
}

export default AlertModal;