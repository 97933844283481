// 默认路由
const defaultRoutes = [
  {//登录
    path: '/login',
    name: 'login',
	meta: {
			title: 'Login'
	},
    component: () => import('@/views/login/index')
  },
  
  {//首页
	  path: '/',
	  name: 'admin',
	  redirect: '/home',
	  meta: {
			needLogin: true //是否需要登录才能进入
	  },
	  component: () => import('@/views/index'),
	  children: [
		 {
			 path: 'home',
			 name: 'home',
			 meta: {
				title: 'Medin Health AI System',
				needLogin: true //是否需要登录才能进入
			 },
			component: () => import('@/views/home/index'),
		 },
		 {
			 path: 'mainNews',
			 name: 'mainNews',
			 meta: {
				title: 'Message Notification',
				needLogin: true //是否需要登录才能进入
			 },
			component: () => import('@/views/information/index'),
		 },
		 // {
			//  path: 'adminCompanyAihui/:id',
			//  name: 'adminCompanyAihui',
			//  meta: {
			//  	title: '企业统计',
			//  	needLogin: true //是否需要登录才能进入
			//  },
			//  component: () => import('@/views/company/aihui'),
		 // },
		 
	  ]
  },
  {//报告打印 - 查看报告
  	  path: '/reportPrint/lookReport',
  	  name: 'lookReport',
  	  meta: {
  			title: '查看报告',
  			needLogin: true //是否需要登录才能进入
  	  },
  	  component: () => import('@/views/reportPrint/lookReport')
  },
  {//报告打印 - 打印模板
  	  path: '/reportPrint/printTemplate',
  	  name: 'printTemplate',
  	  meta: {
  			title: '报告打印',
  			needLogin: true //是否需要登录才能进入
  	  },
  	  component: () => import('@/views/reportPrint/printTemplate')
  },
  {//所有不存在路由进入login
	  path: '*',
	  name: '404',
	  redirect: '/login'
  }
  
];

export default defaultRoutes.concat();