<template>
	<div id="myAlert" class="zzc qjc-fixed">
		<!-- 弹窗 -->
		<div class="alert qjc-bg-fff qjc-absolute">
			
			<!-- 关闭按钮 -->
			<span @click="show = false" class="close qjc-fts-24 qjc-absolute color-extr">&times;</span>
			
			<!-- 标题 -->
			<h3 class="title qjc-fts-20 qjc-texta-c qjc-c-dark">{{ title }}</h3>
			
			<!-- 内容描述 -->
			<div v-html="content" class="content qjc-fts-14">
				
			</div>
			
			<!-- 确认按钮 -->
			<el-button @click="confirm" class="confirm qjc-fts-16 qjc-wid-100">Confirm</el-button>
			
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Alert',
		data() {
			return {
				show: true,// 控制是否移除弹窗DOM
				type: '',// 类型
				title: '',// 标题
				content: ''// 内容
			}
		},
		watch: {
			show(cur, old) {
				//监听show为false时  移除弹窗dom
				if(cur == false){
					let myAlertDom = document.getElementById('myAlert');
					myAlertDom.parentNode.removeChild(myAlertDom);
				}
			}
		},
		
		//只存在一个弹窗(创建前检查是否已存在，如存在，删除)
		beforeCreate() {
			if(document.getElementById('myAlert')){
				let myAlertDom = document.getElementById('myAlert');
				myAlertDom.parentNode.removeChild(myAlertDom);
			}
		},
		
		methods: {
			confirm() {//确认按钮 参数：callback: 关闭弹窗并执行的回调函数  isDelete：关闭弹窗是否移除DOM(默认true)
				this.show = false;
				//如有回调
				typeof(this.callback) == 'function'?this.callback():'';
				console.log(typeof(this.callback) == 'function')
			}
		}
	}
</script>

<style lang="scss">
	.zzc{
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(225,226,230,0.5);
		z-index: 9999;
		
		.alert{
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 3.28rem;
			padding: 0.36rem;
			border-radius: 8px;
			
			.close{
				top: -0.38rem;
				right: -0.1rem;
				cursor: pointer;
			}
			
			.title{
				line-height: 0.2rem;
				padding-bottom: 0.16rem;
			}
			
			.content{
				color: #777F8F;
				line-height: 0.2rem;
			}
			
			.confirm{
				height: 0.4rem;
				margin-top: 0.24rem;
			}
		}
	}
</style>
