// 动态路由列表
// about属性为当前路由相关非字(children)路由(动态添加路由需提取添加)
const routers = [
  // 右侧内容路由(包含左菜单。PS:为主目录/下的children子路由)
  // 企业后台
  {
    path: 'reportPrint',
    name: 'reportPrint',
    meta: {
      title: '报告打印',
      needLogin: true,
    },
    component: () => import('@/views/reportPrint/index'),
    about: [
      {
        //报告打印 - 查看报告
        path: '/reportPrint/lookReport',
        name: 'lookReport',
        meta: {
          title: '查看报告',
          needLogin: true, //是否需要登录才能进入
        },
        component: () => import('@/views/reportPrint/lookReport'),
      },
      {
        //报告打印 - 打印模板
        path: '/reportPrint/printTemplate',
        name: 'printTemplate',
        meta: {
          title: '报告打印',
          needLogin: true, //是否需要登录才能进入
        },
        component: () => import('@/views/reportPrint/printTemplate'),
      },
      {
        //报告打印 - 打印模板
        path: '/reportPrint/printTemplate1',
        name: 'printTemplate1',
        meta: {
          title: '报告打印',
          needLogin: true, //是否需要登录才能进入
        },
        component: () => import('@/views/reportPrint/printTemplate1'),
      },
    ],
  },
  {
    path: 'mainOpen',
    name: 'mainOpen',
    meta: {
      title: 'Products & AI Modules',
      needLogin: true,
    },
    component: () => import('@/views/main/open/index'),
  },
  {
    path: 'mainOpenNew',
    name: 'mainOpenNew',
    meta: {
      title: '套餐开通',
      needLogin: true,
    },
    component: () => import('@/views/main/openNew'),
  },
  {
    path: 'mainInsert',
    name: 'mainInsert',
    meta: {
      title: 'Modification of the data',
      needLogin: true,
    },
    component: () => import('@/views/main/mainInsert/index'),
  },
  {
    path: 'mainHistory',
    name: 'mainHistory',
    meta: {
      title: 'Records of AI reports',
      needLogin: true,
    },
    component: () => import('@/views/main/history/index'),
  },
  {
    path: 'mainPrintDowload',
    name: 'mainPrintDowload',
    meta: {
      title: 'Download the report',
      needLogin: true,
    },
    component: () => import('@/views/main/printDowload/index'),
  },
  //api统计
  {
    path: 'mainAPI',
    name: 'mainAPI',
    meta: {
      title: 'API Statistics',
      needLogin: true,
    },
    component: () => import('@/views/main/mainAPI/index'),
  },
] // 所有路由列表

export default routers
