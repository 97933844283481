import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'

import routers from '@/router/list' // 路由列表
import defaultRoutes from '@/router/default'
import { getMenu } from '@/utils/axios' // 获取权限路由

import ElementUI from 'element-ui'
// rem适应
import remConfig from '@/utils/remConfig'
remConfig()
window.addEventListener('resize', remConfig)

// 按需引入element
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import localeAll from 'element-ui/lib/locale'
import localeEn from 'element-ui/lib/locale/lang/en'
import localeZh from 'element-ui/lib/locale/lang/zh-CN'

import {
  Button,
  Input,
  InputNumber,
  Form,
  FormItem,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Container,
  Main,
  Aside,
  Header,
  Menu,
  Message,
  Submenu,
  MenuItem,
  Select,
  Option,
  DatePicker,
  Radio,
  RadioGroup,
  Table,
  TableColumn,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
  Tree,
  MessageBox,
  Row,
  Col,
  Tooltip,
  Image,
  Switch,
  Steps,
  Step,
  Tag,
  Tabs,
  TabPane,
  Autocomplete,
  Collapse,
  CollapseItem,
  Popover,
  Upload,
} from 'element-ui'

Vue.use(Button)
  .use(Input)
  .use(Form)
  .use(FormItem)
  .use(Checkbox)
  .use(Dialog)
  .use(Container)
  .use(Main)
  .use(Aside)
  .use(Header)
  .use(Menu)
  .use(Submenu)
  .use(MenuItem)
  .use(Select)
  .use(Option)
  .use(DatePicker)
  .use(CheckboxGroup)
  .use(Radio)
  .use(RadioGroup)
  .use(Table)
  .use(TableColumn)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Pagination)
  .use(Tree)
  .use(Tooltip)
  .use(Image)
  .use(Upload)
  .use(Switch)
  .use(InputNumber)
  .use(Steps)
  .use(Step)
  .use(Tag)
  .use(Tabs)
  .use(TabPane)
  .use(Autocomplete)
  .use(Collapse)
  .use(CollapseItem)
  .use(Popover)
  .use(Row)
  .use(Col)

// .use(Message)
Vue.use(VueI18n)

import enLocale from './common/lang/en' // 本地英文包
import zhLocale from './common/lang/zh' // 本地中文包

import '@/styles/setElementUi.css' // 修改element默认样式
import '@/styles/public_qjc.css' // 自定义通用css

// 自定义弹窗
import myAlert from '@/components/Alert'
Vue.prototype.$myAlert = myAlert

Vue.prototype.$confirm = MessageBox.confirm

Vue.prototype.$message = Message

Vue.config.productionTip = false

import Toast from '@/components/Toast/index'
Vue.prototype.$Toast = Toast

Vue.directive('fo', {
  inserted(el, binding, vnode) {
    // 聚焦元素
    el.querySelector('input').focus()
  },
})

const messages = {
  en: {
    ...enLocale, // es6的拓展运算符，相当于解析出每个对象
    // ...locale_en
  },
  zh: {
    ...zhLocale,
    // ...locale_zh
  },
}
let language = sessionStorage.getItem('theLanguage')
  ? sessionStorage.getItem('theLanguage')
  : 'en'
if (language == 'zh') {
  localeAll.use(localeZh)
} else if (language == 'en') {
  localeAll.use(localeEn)
}
if (!sessionStorage.getItem('theLanguage')) {
  sessionStorage.setItem('theLanguage', 'en')
}

const i18n = new VueI18n({
  // locale: localStorage.getItem('lang') || 'zh',    // 语言标识
  locale: language, // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages,
  silentTranslationWarn: true,
})
console.log(language)
// this.$i18n.locale = lang

// 两小时未进行操作退出登录状态
var noHandle // 定时器存储
var noHandleFun = () => {
  noHandle = setTimeout(() => {
    store.dispatch('logout')
  }, 28800000)
}
noHandleFun()
var claerHandle = () => {
  clearTimeout(noHandle)
  noHandleFun()
}
window.onmousemove = claerHandle
window.onclick = claerHandle
window.onkeyup = claerHandle

router.beforeEach((to, from, next) => {
  //是否登录
  if (store.state.isLogin) {
    if (store.state.menus.length > 0) {
      next()
    } else {
      // 获取权限路由并添加
      getMenu({
        act: 'menu',
      }).then((res) => {
        if (res.code == 200) {
          let data = JSON.parse(window.atob(res.data))
          var list = data
          // 权限菜单存储
          store.commit('addMenus', list)
          // console.log(list)
          // localStorage.setItem('menusList', JSON.stringify(list))

          // 添加路由
          var routersList = [] // 路由列表
          var parentRouter = defaultRoutes.filter(
            (item) => item.name == 'admin'
          )[0]
          // 递归添加菜单
          var addMenus = (list) => {
            for (var i = 0; i < list.length; i++) {
              // 在已有路由中查找相关路由
              var rt = routers.filter((r) => r.path == list[i].url)[0]

              // 是否存在相关路由
              if (rt && rt.about) {
                routersList = routersList.concat(rt.about)
                delete rt.about
              }

              if (/^\//.test(list[i].url)) {
                // 非右侧内容路由(无左侧菜单,即 非admin路由/下children子路由)
                routersList.push(rt)
              } else if (list[i].url) {
                // 右侧内容路由(包含左侧菜单,即admin路由/下children子路由)
                if (rt) {
                  parentRouter.children.push(rt)
                }
              }

              // 子路由
              if (list[i].son && list[i].son.length > 0) {
                addMenus(list[i].son)
              }
            }
          }
          addMenus(list)
          router.addRoutes(routersList.concat(parentRouter))
          let historyRoute = sessionStorage.getItem('historyRoute')

          // next(to.fullPath)
          // 判断 上一次route 不为登录页  和 即将跳转不为查看报告页、打印报告
          console.log(historyRoute)
          next(
            historyRoute &&
              historyRoute != '/login' &&
              to.path != '/reportPrint/lookReport' &&
              to.path != '/reportPrint/lookReport1' &&
              to.path != '/reportPrint/printTemplate'
              ? historyRoute
              : to.fullPath
          )
          // && to.path != '/downlist/doctorLookReport'  && to.path != '/downlist/doctorPrintTemplate'
        } else {
          alert(res.msg)
          next()
        }
      })
    }
  } else {
    if (to.meta.needLogin) {
      next({
        path: '/login',
        // query: {
        // 	toUrl: to.fullPath // 保存用户打算进入页面，登录后直接进入
        // }
      })
    } else {
      next()
    }
  }
})

router.afterEach((to, from, next) => {
  //当前页面title
  document.title = to.meta.title

  // 页面跳转后回到顶部
  window.scroll(0, 0)
})

const _vm = new Vue({
  i18n,
  router,
  store,
  render: function (h) {
    return h(App)
  },
}).$mount('#app')

window._vm = _vm
