import axios from 'axios' //这里引入axios  mian无需再引
import Vue from 'vue' //引入Vue
Vue.prototype.$ajax = axios //修改ajax原型指向为 axios
import Qs from 'qs' // 引入Qs，这个库是axios里面包含的，不需要再下载了

import store from '@/store'

import { Base64 } from 'js-base64'

var url = window.location.href
export const api =
  process.env.VUE_APP_FLAG == 'dev'
    ? 'http://apitest.medinhealth.com/'
    : 'https://api.medinhealth.com/'

console.log('env', process.env)

// 超时设定
axios.defaults.timeout = 15000

axios.interceptors.request.use(
  (config) => {
    return config
  },
  (err) => {
    alert('请求超时')
    return Promise.resolve(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    const data = response.data
    const _that = window._vm
    const router = _that.$router
    // 根据返回的code值来做不同的处理(和后端约定)
    switch (data.code) {
      case 401:
        // 未登录 清除已登录状态
        localStorage.clear()
        sessionStorage.clear()
        store.dispatch('logout')
        setTimeout(() => {
          if (router.history.current.name !== 'login') {
            if (data.msg) {
              console.log('错误信息:', data.msg)
              alert(data.msg)
            } else {
              alert('未知错误，请重新登录')
            }
            router.push('/login')
          }
        }, 200)

        break
      case 403:
        // 没有权限
        if (data.message !== null) {
          alert(data.message)
        } else {
          alert('未知错误')
        }
        break
      case 500:
        // 错误
        if (data.message !== null) {
          alert(data.message)
        } else {
          alert('未知错误')
        }
        break
      default:
        return data
    }
    return data
  },
  (err) => {
    // 返回状态码不为200时候的错误处理
    return Promise.resolve(err)
  }
)
//封装axios
const axiosPost = (
  url,
  data,
  type,
  headers,
  contentType,
  processData,
  async
) => {
  if (store.state.isLogin) {
    //已登录添加token
    const token = localStorage.getItem('access_token')
    Object.assign(data, { access_token: token })
  }
  if (type == null || type == undefined) {
    type = 'GET'
  }
  if (headers == null || headers == undefined) {
    headers = {}
  }
  if (contentType == null || contentType == undefined) {
    contentType = 'application/x-www-form-urlencoded; charset=UTF-8'
  }
  if (processData == null || processData == undefined) {
    processData = true
  }
  if (async == null || async == undefined) {
    async = true
  }

  return axios({
    url: api + url,
    method: type,
    data: Qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Content-Type': 'application/json',
      // 'accessToken': accessToken
    },
  })
}
const axiosPost2 = (
  url,
  data,
  type,
  headers,
  contentType,
  processData,
  async
) => {
  if (type == null || type == undefined) {
    type = 'GET'
  }
  if (headers == null || headers == undefined) {
    headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    // headers = {'Content-Type': 'multipart/form-data'}
  }
  if (contentType == null || contentType == undefined) {
    contentType = 'application/x-www-form-urlencoded; charset=UTF-8'
  }
  if (processData == null || processData == undefined) {
    processData = true
  }
  if (async == null || async == undefined) {
    async = true
  }

  return axios({
    url: api + url,
    method: type,
    data: Qs.stringify(data),
    headers: Object.assign(
      { 'x-token': localStorage.getItem('access_token') },
      headers
    ),
  })
}

const axiosPost3 = (
  url,
  data,
  type,
  headers,
  contentType,
  processData,
  async
) => {
  if (type == null || type == undefined) {
    type = 'GET'
  }
  if (headers == null || headers == undefined) {
    headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    // headers = {'Content-Type': 'multipart/form-data'}
  }
  if (contentType == null || contentType == undefined) {
    contentType = 'application/x-www-form-urlencoded; charset=UTF-8'
  }
  if (processData == null || processData == undefined) {
    processData = true
  }
  if (async == null || async == undefined) {
    async = true
  }

  return axios({
    url: api + url,
    method: type,
    data: data,
    headers: Object.assign(
      { 'x-token': localStorage.getItem('access_token') },
      headers
    ),
  })
}

//登录
export const login = (data) => {
  return axiosPost(
    'v1/admin/login/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

//发送验证码
export const sendsms = (data) => {
  return axiosPost(
    'v1/user/sendsms/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

// 数据录入和修改(数据修改多传一个参数 report_code) 企业后台
export const health = (data) => {
  return axiosPost2(
    'v1/yyadmin/health/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

// 数据修改查询
export const dataQuery = (data) => {
  return axiosPost2('v1/yyadmin/query/', data, 'post')
}

// 套餐信息 企业 母婴公用
export const getPackage = (data) => {
  return axiosPost2(
    'v1/yyadmin/package/',
    { data: btoa(JSON.stringify(data)) },
    'post'
  )
}

// 历史记录 企业后台
export const getHistory = (data) => {
  return axiosPost2('v1/yyadmin/history/', data, 'post')
}

// 批量导出/打印-查询 报告打印  企业后台
export const printBatchQuery = (data) => {
  return axiosPost2('v1/yyadmin/list/', data, 'post')
}

// 母婴后台
//获取菜单
export const getMenu = (data) => {
  return axiosPost2(
    'v1/admin/adminMenu/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}
// 数据修改
// 查询列表记录
export const getsbpList = (data) => {
  return axiosPost2('v1/admin/sbpList/', data, 'post')
}

// 套餐开通
export const sbpOpen = (data) => {
  return axiosPost2(
    'v1/admin/sbpOpen/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}
// 数据录入和修改(data含id为修改，否则为录入)
export const sbpHealth = (data) => {
  return axiosPost2(
    'v1/admin/sbpHealth/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}
// 数据修改查询
export const sbpEditQuery = (id) => {
  return axiosPost2('v1/admin/sbpEdit/' + id, {}, 'post')
}

// 管理员模块
// 管理员
// 列表 添加和修改(修改参数含id) 修改查询
export const admin = (data) => {
  return axiosPost2(
    'v1/admin/admin/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

// 角色 列表 添加和修改 修改查询
export const adminRole = (data) => {
  return axiosPost2(
    'v1/admin/adminRole/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

// 修改密码
export const adminProfile = (data) => {
  return axiosPost2(
    'v1/admin/profile/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

// 企业管理
// 列表
export const enterpriseList = (data) => {
  return axiosPost2(
    'v1/admin/company/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}

// 菜单
// 列表 添加和修改(修改参数含id) 修改查询
export const adminMenu = (data) => {
  return axiosPost2(
    'v1/admin/adminMenu/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

// 一级菜单查询
export const topMenu = () => {
  return axiosPost2('v1/admin/topMenu/', {}, 'post')
}

// 头部清除缓存
export const clearCache = () => {
  return axiosPost2('v1/admin/clearCache/', {}, 'post')
}

// 大陆体检后台
// 数据录入
// 查询
export const mainDataQuery = (data) => {
  return axiosPost2(
    'v1/admin/list/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}
// 录入提交
export const mainDataSubmit = (data) => {
  return axiosPost2(
    'v1/admin/bioche/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

// 预约信息调取
export const getbookingInfo = (data) => {
  return axiosPost2(
    'v1/backmain/appointlist/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

// 设置邮箱提醒
export const emailNotice = (data) => {
  return axiosPost2(
    'v1/backmain/setting/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

//获取验证码 参数一：账号  参数二：验证码类型(1.手机号注册 2 手机找回密码 3 设置邮箱通知 90.邮箱注册 91.邮箱找回密码)
export const getVerifyCode = (data) => {
  return axiosPost2('v1/user/sendsms/', data, 'post')
}

//礼品卡
export const getGiftCardList = (data) => {
  return axiosPost2(
    'v1/backmain/giftCardList/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

//礼品卡  备注 act: 'query' 查询, 'update' 修改, 'disable' 冻结, 'enable' 解冻
export const getGiftRemark = (data) => {
  return axiosPost2(
    'v1/backmain/giftCardRemark/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

// // act: 'query' 查询, 'update' 修改, act: 'add'
export const getArticle = (data) => {
  // return axiosPost2('v1/backmain/article/', { data: encodeURIComponent(JSON.stringify(data)) }, 'post');
  return axiosPost2(
    'v1/backmain/article/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}
//上传文件
export const getArticle1 = (data) => {
  // return axiosPost2('v1/backmain/article/', { data: encodeURIComponent(JSON.stringify(data)) }, 'post');
  return axiosPost2(
    'v1/backmain/article/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}

//新版后台
// 套餐开通
// export const packageOpen = (data) => {
//     return axiosPost2('v1/admin/health/', { data: encodeURIComponent(JSON.stringify(data)) }, 'post');
// };

export const packageOpen = (data) => {
  return axiosPost2(
    'v1/admin/health/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}

//消息通知
export const messageNews = (data) => {
  return axiosPost2(
    'v1/admin/message/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

//数据统计
export const getStatistics = (data) => {
  return axiosPost2(
    'v1/admin/tongji/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

//数据统计 Api
export const getStatisticsApi = (data) => {
  return axiosPost2('v1/admin/apiList/', data, 'post')
}

//数据统计 aihui Api
export const getAihuiTime = (data) => {
  return axiosPost2(
    'v1/aihui/manage/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}
export const getAihui = (data) => {
  return axiosPost2('v1/aihui/apiList/', data, 'post')
}

//数据统计 Kangxi Api
export const getKangxiTime = (data) => {
  return axiosPost2(
    'v1/kangxi/manage/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}
export const getKangxi = (data) => {
  return axiosPost2('v1/kangxi/apiList/', data, 'post')
}

//待处理订单 医生
export const getDoctorOrder = (data) => {
  return axiosPost2(
    'v1/admin/zwyc/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

//三亚
export const getSanyaList = (data) => {
  return axiosPost2(
    'v1/sanya/back/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

export const sanyaReport = (data) => {
  return axiosPost3('v1/sanya/upload/', data, 'post')
}

export const sanyaAdd = (data) => {
  return axiosPost2(
    'v1/sanya/back/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

export const sanyaReportList = (data) => {
  return axiosPost2(
    'v1/sanya/freeList/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

//优惠码
export const getCoupon = (data) => {
  return axiosPost2(
    'v1/admin/coupon/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

//订单列表
export const getOrder = (data) => {
  return axiosPost2(
    'v1/admin/order/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

//套餐信息
export const getGoods = (data) => {
  return axiosPost2(
    'v1/admin/goods/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

//企业管理  国安广传
export const getGagc = (data) => {
  return axiosPost2(
    'v1/admin/corp/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

//商品信息
export const getGoodsList = (data) => {
  return axiosPost2('v1/user/goodsList/', data, 'post')
}

//账号对应 商品信息
export const getUserGoodsList = (data) => {
  return axiosPost2('v1/admin/goodsList/', data, 'post')
}

//意见反馈
export const getFeedbacksList = (data) => {
  return axiosPost2(
    'v1/admin/feedback/',
    { data: window.btoa(JSON.stringify(data)) },
    'post'
  )
}

//常见问题
export const getFaqList = (data) => {
  return axiosPost2(
    'v1/admin/faq/',
    { data: encodeURIComponent(JSON.stringify(data)) },
    'post'
  )
}

//用户列表
export const getUserList = (data) => {
  return axiosPost2(
    'v1/admin/user/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}

//用户列表
export const getImgList = (data) => {
  return axiosPost2(
    'v1/admin/file/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}

const axiosPostImg = (
  url,
  data,
  type,
  headers,
  contentType,
  processData,
  async
) => {
  if (type == null || type == undefined) {
    type = 'GET'
  }
  if (headers == null || headers == undefined) {
    headers = { 'Content-Type': 'multipart/form-data' }
    // headers = {'Content-Type': 'multipart/form-data'}
  }
  if (contentType == null || contentType == undefined) {
    contentType = 'application/x-www-form-urlencoded; charset=UTF-8'
  }
  if (processData == null || processData == undefined) {
    processData = true
  }
  if (async == null || async == undefined) {
    // async = false
    async = true
  }

  return axios({
    url: api + url,
    method: type,
    // data:Qs.stringify(data),
    data: data,
    headers: Object.assign(
      { 'x-token': localStorage.getItem('access_token') },
      headers
    ),
  })
}
//上传图片
export const uploadImg = (data) => {
  // console.log(data)
  return axiosPostImg('v1/user/upload/', data, 'post')
}

//用户列表
export const getGpt = (data) => {
  return axiosPost2(
    'v1/admin/qianfan/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}

// 闪休
//用户列表
export const getSxList = (data) => {
  return axiosPost2(
    'v1/backshanxiu/list/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}

//优惠码
export const getSxCoupon = (data) => {
  return axiosPost2(
    'v1/backshanxiu/coupon/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}

//用户列表
export const getSxDetail = (data) => {
  return axiosPost2(
    'v1/backshanxiu/detail/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}
//全球国家列表   英文
export const getCountryList = (data = {}) => {
  return axiosPost2(
    'v1/admin/countryList/',
    { data: Base64.encode(JSON.stringify(data)) },
    'post'
  )
}
