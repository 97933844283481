module.exports = {
  home: {
    text1: 'Welcome',
    text2: 'This login is IP',
    text3: 'This login time',
    text4: 'last login IP',
    text5: 'last login time',
    text6: "Last week's stats",
    text7: 'user registrations',
    text8: 'Number of Orders',
    text9:
      'This background system is developed by the Shanghai Miin Big Data Technology Development Team, technical support',
  },
  admin: {
    t1: 'Menu List',
    t2: 'Add Menu',
    t3: 'Modify Menu',
    t4: 'Menu name',
    t5: 'First-level Menu',
    t6: 'Icon name',
    t7: 'Route name',
    t8: 'Sort',
    t9: 'Show',
    t10: 'Number',
    t11: 'Operation',
    t12: 'Name in English',
  },
  goods: {
    t1: 'Manage Products',
    t2: 'Add Product',
    t3: 'Number',
    t4: 'Product Image',
    t5: 'Product Name',
    t6: 'Price',
    t7: 'Market Price',
    t8: 'Blood Test Price',
    t9: 'Status',
    t10: 'Date of operation',
    t11: 'Operation',
    t12: 'Sort',
    t13: 'History gallery',
    t14: 'Add',
    t15: 'Modify',
    t16: 'Please enter',
    t17: 'Submit',
    t18: 'Cancel',
    t19: 'Product',
    t20: 'Image',
    t21: 'Please enter a Name',
    t22: 'Please enter the Price',
    t23: 'Please enter Market Price',
    t24: 'Code',
  },
  open: {
    a1: 'This package is only available for people between the ages of 20 and 90!',
    a2: 'This package is only available for people between the ages of 40 and 80!',

    t1: 'Personal Information',
    t101: 'Select package',
    t2: 'Questionnaire Information',
    t3: 'Physiques Information',
    t4: 'Biochemistry Information',
    t5: 'Cell phone number',
    t6: 'Business User ID',
    t7: 'Gender',
    t8: "Client's Date of Birth",
    t9: 'Company or Agency',
    t10: 'Date of medical examination',
    t11: 'Contents of Products & AI Modules',
    t12: 'Previous step',
    t13: 'Next step',
    t14: 'Male',
    t15: 'Female',
    t16: 'I. Basic information',
    t17: "1. Client's place of residence",
    t18: "2. Client's alcohol consumption habbit (single choice)?",
    t19: '3. Client smoke or not (single choice)?',
    t20: "4. Customers' dining habit (single choice)?",
    t21: '5. Is the client a full-time vegetarian (single choice)?',
    t22: "6. Client's exercise habit (single choice)?",
    t23: '7. Does the client have sleep disorders (single choice)?',
    t24: '8. Does the client suffer from any of the following diseases?',
    t25: '9. Has the client had any of the following operations before?',
    t26: "10. Does a member of the client's immediate family have any of the following diseases?",

    t27: 'II. Personal illness history',
    t28: 'III. Personal operation history',
    t29: 'iv. Immediate family illness history',
    t30: 'Height',
    t31: 'Weight',
    t311: 'Chest',
    t32: 'Waistline',
    t33: 'Hipline',
    t34: 'Body fat',
    t35: 'If the body fat rate is not filled, it will be automatically generated based on your information',
    t36: 'Blood Pressure Check',
    t37: 'Routine blood tests',
    t38: 'Glucose Screening',
    t39: 'Lipid Screening',
    t40: 'Liver Function Tests',
    t41: 'Kidney Function Tests',
    t42: 'Systolic blood pressure (SBP)',
    t43: 'Diastolic blood pressure (DBP)',
    t44: 'White Blood Cells (WBC)',
    t45: 'Haemoglobin (HGB)',
    t46: 'Platelets (PLT)',
    t47: 'Fasting Glucose(FG)',
    t48: 'Glycated hemoglobin (HBA1C)',
    t49: 'Total Cholesterol (CHOL)',
    t50: 'Low-density lipoprotein (LDLC)',
    t51: 'High-density lipoprotein (HDL)',
    t52: 'Triglycerides (TG)',
    t53: 'Glutamine aminotransferase (GOT/AST)',
    t54: 'Glutathione (GPT/ALT)',
    t55: 'Alkaline phosphatase (ALP)',
    t56: 'Serum albumin (ALB)',
    t57: 'Creatinine (CRE)',
    t58: 'Uric acid (UA)',
    t59: 'Glomerular filtration rate (EGFR)',
    t60: 'Optional',
    t61: 'Confirmation of submission',
    t62: 'Mom information',
    t63: 'Baby information',
    t64: 'Fracture Questionnaire',

    resetForm: 'pre step',

    q1: 'No',
    q2: 'daily',
    q3: 'often',
    q4: 'occasionally',
    q5: 'No',
    q6: 'daily',
    q7: 'often',
    q8: 'occasionally',
    q9: 'Regularly and in fixed amounts',
    q10: 'Irregularly and not in fixed amounts',
    q11: 'Regularly but not in fixed amounts',
    q12: 'Irregularly but in fixed amounts',
    q13: 'No',
    q14: 'Yes',
    q15: 'None or less than one hour per week',
    q16: 'Exercise 1~2 hours per week',
    q17: 'Exercise 3~4 hours per week',
    q18: 'Exercise 5~6 hours per week',
    q19: 'Exercise more than 7 hours per week',
    q20: 'None',
    q21: 'Very serious',
    q22: 'Frequently',
    q23: 'Occasionally',
    q24: 'No',
    q25: 'Yes, please select medication status (multiple choice)',
    q26: 'Disease name',
    q27: 'Do not take medication',
    q28: 'Occasional medication',
    q29: 'Take medication regularly',
    q30: 'Yes, please select (multiple choice)',

    f1: '4. Has the client ever had a fracture (radio)?',
    f2: "5. Has the client's parent ever had a hip fracture (single choice)?",
    f3: "6. Client's daily living and transactional decision making processing and cognitive abilities? (single choice)?",
    f4: '7. Femoral neck bone mineral density (BMD)?',
    f5: '8. Has the client ever suffered from any of the following?',
    f6: '9. Does the client require health care services?',

    f7: "Don't know",
    f8: 'Autonomous decision, always and reasonably',
    f9: 'Partial autonomy, can have difficulties with new situations',
    f10: 'Most cases are not autonomous, or poorly decided, or require prompting',
    f11: "Don't know",
    f12: 'Please enter the number of times the client has ever broken a bone',
    f13: 'Please enter bone mineral density',
    f14: 'Please select if the client suffers from any of the following diseases',
    f15: "Please select the customer's place of residence",
    f16: "Please select the customer's alcohol consumption habbit",
    f17: 'Please select whether the customer is a smoker or not',
    f18: 'Please select whether the client has ever sustained a bone fracture',
    f19: "Please select if the client's parents ever had a hip fracture",
    f20: "Please select the client's cognitive ability to live on his/her own",
    f21: "Please fill in the value of the client's bone density",
    f22: 'Please enter the number of times the client has ever broken a bone',
    f23: 'No',
    f24: 'Yes',

    f25: 'Please enter cell phone number',
    f26: 'Please enter commercial user ID',
    f27: 'Fill in at least one of the cell phone number and commercial user ID.',
    f28: 'Please select',
    f29: 'Please select date of birth',
    f30: 'Please select the date of medical examination',
    f31: 'The date of medical examination is later than the date of birth and cannot be later than the current time',
    f32: 'Please select a product',
    f33: 'Please select a product or AI module',
    f34: "Please select the customer's dining habit",
    f35: 'Please select if the customer is a full-time vegetarian',
    f36: "Please select the client's exercise habit",
    f37: 'Please select if the client has a sleep disorder',
    f38: 'Please select medication',
    f39: 'Please fill in your height',
    f40: 'Please fill in your weight',
    f41: 'Please fill in the height',
    f42: 'Please fill in the weight',
    f43: 'If you do not fill in the waistline, it will be automatically generated according to your information.',
    f44: 'If you do not fill in the hipline, it will be automatically generated according to your information.',
    f45: 'If you do not fill in the Body fat, it will be automatically generated based on your information.',
    f46: 'Cannot be empty',
    f47: 'Please enter (optional)',
    f48: 'Please enter white blood cells',
    f49: "Please select the client's gender",
    f50: 'Please fill in your waistline',
    f501: 'Please fill in your waistline (optional)',
    f51: 'Please fill in your hip circumference',
    f511: 'Please fill in your hip circumference (optional)',
    f52: 'Please enter your bust',
    f53: 'Please fill in your bust',
    f54: 'Height between 130 and 200',
    f55: 'Weight between 30 and 160',
    f56: 'Bust between 40~165',
    f57: 'waistline between 40~150',
    f58: 'Hip circumference between 60~165',
    f59: 'Body fat percentage between 3~75',

    f60: 'Confirmation of input information',
    f61: 'The results you fill in may have large deviations, please double check that the values and units used are correct and consistent with your report.<br/>If you have checked and confirmed and insist on submitting and evaluating with the result, we may correct and process the result to a certain extent according to the big data algorithm in order to provide a more reasonable big data evaluation result and recommendation.',

    m1: 'Prenatal body shape information',
    m2: 'Postnatal body image information',
    m3: 'Weeks of pregnancy',
    m4: 'Mom information',
    m5: 'Was the following illness within two years prior to delivery? (Multiple choice)',
    m6: 'Please fill in your week of pregnancy',
    m7: "Mother's childbearing age",
    m8: "Baby's gestational week of birth",
    m9: "Baby's birth weight",
    m10: 'Sex of the baby',
    m11: 'APGAR score',
    m12: 'APGAR scores - i.e. neonatal scores (first minute) are 0-10',
    m13: "Please enter your baby's correct gestational week of birth",
    m14: 'Please enter the correct childbearing age of the mother',
    m15: "Please enter your baby's correct birth weight",
    m16: 'Please select the sex of your baby',
    m17: "Please enter the mother's childbearing age",
    m18: "Please enter your baby's gestational week of birth",
    m19: "Please enter your baby's birth weight",
    m20: 'Please enter APGAR score - i.e. newborn score (first minute)',
    m21: 'Baby information',
  },
  table: {
    t1: 'Serial number',
    t2: 'Cell phone number/commercial user ID',
    t3: 'Contents & AI modules',
    t4: 'Gender',
    t5: 'Date of birth',
    t6: 'Date of medical examination',
    t7: 'Status',
    t8: 'Number of operations',
    t9: 'operation',
    t10: 'operator',
    t11: 'operating time',
    t12: 'Start date',
    t13: 'End date',
    t14: 'All Products',
    t15: 'Date of report using',
    t16: 'commercial user identification',
    t17: 'payment method',
    t18: 'Already used',
    t19: 'Left',
    t20: 'View Details',
    t21: 'name',
    t22: 'free',
    t23: 'I.D. number',
    t24: 'Product & AI module name',
    t25: 'dates',
    t26: 'postpaid',
    t27: 'prepay',
    t28: 'Messages',
    t29: 'Set up',
    t30: 'Exit',
    t31: 'Sidebar Skin',
    t32: 'light color',
    t33: 'dark colored',
    t34: 'Total',
    t35: 'Total',

    t36: 'Finished',
    t37: 'Unfinished',
    t38: 'Modify',
    t39: 'Consult',
    t40: 'Reset',
    t41: 'View',
    t42: 'Download',

    t43: 'Please enter Cell phone number or commercial user ID',
    t44: 'Open up',
    t45: 'times',

    t46: 'language',

    t47: 'Modify Password',

    t48: 'Account Name',
    t49: 'Original Password',
    t50: 'New Password',
    t51: 'Confirm New Password',

    t52: 'No data available',
  },

  diseases: {
    d1: 'Hypertension',
    d2: 'Hyperlipidemia',
    d3: 'Diabetes',
    d4: 'Chronic liver disease',
    d5: 'Chronic kidney disease',
    d6: 'Cancer',
    d7: 'Cirrhosis',
    d8: 'chronic obstructive pulmonary disease',
    d9: 'Arthritis',
    d10: 'Heart Disease',
    d11: 'Cerebral stroke',
    d12: 'Gastric Ulcer',
    d13: 'Sleep Disorder',
    d14: 'Anemic',
    d15: 'Pain',
    d16: 'Asthma',
    d17: 'Gout',
    d18: 'Hemorrhoids',
    d19: 'Thyroid Diseases',
  },
  oper: {
    d1: 'Head or brain',
    d2: 'eyes, nose and ears',
    d3: 'Oral cavity, esophagus',
    d4: 'chest(heart, lung, etc.)',
    d5: 'Abdomen (stomach, liver, gallbladder, etc.)',
    d6: 'Joints and Bones',
    d7: 'Gynecological surgery',
    d8: 'urinary system',
  },
  famdis: {
    d1: 'Cancer',
    d2: 'Stroke',
    d3: 'Myocardial infarction',
    d4: 'Cirrhosis',
    d5: 'Senile dementia',
    d6: 'Chronic obstructive pulmonary disease',
    d7: 'Tuberculosis',
    d8: 'Congenital malformation',
  },
  // 客户居住地
  areaList: [
    { text: 'Jiangsu Province', code: '3200' },
    { text: 'Shanghai City', code: '3100' },
    { text: 'Beijing City', code: '1100' },
    { text: 'Tianjin City', code: '1200' },
    { text: 'Zhejiang Province', code: '3300' },
    { text: 'Guangdong Province', code: '4400' },
    { text: 'Liaoning Province', code: '2100' },
    { text: 'Shandong Province', code: '3700' },
    { text: 'Chongqing City', code: '5000' },
    { text: 'Fujian Province', code: '3500' },
    { text: 'Hainan Province', code: '4600' },
    { text: 'Hubei Province', code: '4200' },
    { text: 'Hunan Province', code: '4300' },
    { text: 'Shaanxi Province', code: '6100' },
    { text: 'Henan Province', code: '4100' },
    { text: 'Heilongjiang Province', code: '2300' },
    { text: 'Qinghai Province', code: '6300' },
    { text: 'Yunnan Province', code: '5300' },
    { text: 'Anhui Province', code: '3400' },
    { text: 'Sichuan Province', code: '5100' },
    { text: 'Guizhou Province', code: '5200' },
    { text: 'Gansu Province', code: '6200' },
    { text: 'Jiangxi Province', code: '3600' },
    { text: 'Jilin Province', code: '2200' },
    { text: 'Shanxi Province', code: '1400' },
    { text: 'Hebei Province', code: '1300' },
    { text: 'Ningxia Hui Autonomous Region', code: '6400' },
    { text: 'Guangxi Zhuang Autonomous Region', code: '4500' },
    { text: 'Xinjiang Uyghur Autonomous Region', code: '6500' },
    { text: 'Inner Mongolia Autonomous Region', code: '1500' },
    { text: 'Xizang Autonomous Region', code: '5400' },
    { text: 'Taiwan, China', code: '0000' },
    { text: 'Hong Kong, China', code: '4401' },
  ],

  goodsList: [
    { id: '5', goods_id: '5', type: 'AIZH', name: '[AI]Smart Health' },
    { id: '6', goods_id: '6', type: 'AIFB', name: '[AI]Smart Fracture-Risk' },
    {
      id: '7',
      goods_id: '7',
      type: 'AIBG',
      name: '[AI]VIP Health Risk Examination',
    },
    {
      id: '3',
      goods_id: '3',
      type: 'AIMB',
      name: '[AI]Postpartum Risk (Mother & Newborn)',
    },
    { id: '9', goods_id: '9', type: 'M22', name: '[AI]4-Hypers Analysis' },
  ],

  aijk: {
    a1: 'You are classified as',
    a2: 'healthy people',
    a3: 'subhealthy people',
    a4: 'Medical care needed people',
    a5: 'height',
    a6: 'weight',
    a7: 'waistline',
    a8: 'hipline',
    a9: 'Body fat rate(BFR)',
    a10: 'probability of reaching 90 years old',
    a11: 'probability of reaching 100 years old',
    a12: 'According to the evaluation of the health big data, your health condition is in the category of',
    a13: 'healthy ',
    a14: 'sub-healthy',
    a15: 'Medical treament needed',
    a16: 'Your Medin Health Score is',
    a17: 'score',
    a18: 'I. physique assessment',
    a19: 'physique is one of the most important indicators of health.',
    a20: 'An unhealthy lifestyle can have a direct impact on physique while posing health risks.',
    a21: 'spare',
    a22: 'compact',
    a23: 'fit',
    a24: 'slim',
    a25: 'Moderate',
    a26: 'stout',
    a27: 'hidden-obese',
    a28: 'obese',
    a29: 'Your physique classified as',
    a30: 'II. Weight and physique Improvement Program (12 weeks)',
    a31: 'Everyone has an optimal physique that suits them. Being too fat or too thin is not good, so please pay attention to the improvement target and plan, and maintenance of your physique.',
    a32: 'Current weight',
    a33: 'Recommended weight',
    a34: 'Current WHR',
    a35: 'Recommended WHR',
    a36: 'III. Estimation of medical & healthcare expense(based on hospital general expense in your living area)',
    a37: 'Predict your future medical costs based on your overall health status and risk of disease.',
    a38: 'Predict your medical costs for the coming year',
    a39: 'IV. Future Disease Risk Projections - Acute and Serious Disease Risks',
    a40: 'Prolonged illness often leads to acute episodes of various critical illnesses, accompanied by rapid deterioration of health and even life-threatening conditions.',
    a41: 'Acute and serious illnesses can result in hundreds of thousands of dollars in medical expenses and cause significant, irreversible damage to the body.',
    a42: 'These are the types of acute and critical illnesses for which you have the highest probability of being at risk.',
    a43: 'People Like Me (PLM): People who are similar to you - people of the same gender and age group as you.',
    a44: 'Risk ranking',
    a45: 'IV. Future Disease Risk Predictions - systemic disease risk',
    a46: 'Systematic disease risk probabilities encompass all risk probabilities from minor to major diseases within one system in one year.',
    a47: 'There are nine physiological systems in the human body, and these are the five systems where you are most at risk for disease.',
    a48: 'IV. Future Disease Risk Predictions - Chronic Disease Risks',
    a49: 'As the third state of the human body, the subhealthy state can be transformed into both healthy and sick.',
    a50: 'If the human body is in a state of subhealth for a long time without health management and disease prevention in time, it will be very likely to induce some chronic diseases.',
    a51: 'These are the chronic diseases for which you have the highest probability of being at risk.',

    a52: 'relatively low',
    a53: 'moderate',
    a54: 'relatively high',

    a55: 'main symptom',

    d1: 'Download the report',
    d2: 'Medin Health AI System',
    d3: 'We recommend that you browse online for a more intuitive chart analysis and a better browsing experience.',
    d4: 'Date of examination',
    d5: 'telephone',
    d6: 'Health AI Assessment Report',
    d7: 'Medin AI Smart Healthcare',
    d8: 'AI Physical Examination Analytics and Forecasting Report', //63

    d9: 'Medical Big Data + Artificial Intelligence technology',
    d10: "Medin research team process massive data on health, disease and death to make different risk prediction and analysis, these big data base contains more than 25 million Asian people full-life-cycle data of more than 25 consecutive years of health and medical records; the purpose is to assess the overall health of the person's current situation, predicting the future health risk of the evolution of the trend to provide a 'cure' to improve people's health and stop or delay disease, in other words, we can provide improvement solutions for the 'treatment of future diseases', then everyone can embrace health and stay away from diseases as far as they can, which is a major trend in the development of today's intelligent society and for the development of human being in the future.",
    d11: 'Medin AI Smart Healthcare Assesses the Health Risks for You',
    d12: 'May your health be your constant companion',
    d13: 'This AI analysis report only serves as a reference for health risk alerts and health management, and is not a clinical diagnosis issued by a professional organization or doctor. If you have any questions or needs, please seek for help of professional healthcare organizations and personnel.',
    d14: 'Basic Information',
    d15: 'gender',
    d16: 'age',
    d17: 'Questionnaire Information',
    d18: 'current address',
    d19: 'Drink or not?',
    d20: 'Smoke or not?',
    d21: 'dining habits',
    d22: 'Full-time vegetarians or not?',
    d23: 'exercise habit',
    d24: 'Presence of sleep disorders',
    d25: 'Diseases suffered from',
    d26: 'Illnesses for which no medication is taken',
    d27: 'Illnesses for which occasional medication is taken',
    d28: 'Illnesses for which medication is regularly taken',
    d29: 'Surgeries performed',
    d30: 'Diseases suffered by immediate family members',
    d31: 'Body fat rate(BFR)  from low',
    d32: 'BMI from low',
    d33: 'high',
  },
  menus: localStorage.getItem('menusList')
    ? JSON.parse(localStorage.getItem('menusList'))
    : [],
}
