import Vue from 'vue'
import VueRouter from 'vue-router'

import defaultRoutes from './default.js'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: defaultRoutes
});

export default router
