<template>
	<!-- 过度动画 -->
	<transition name="fade">
		<div class="toast-container" v-if="visible">
			<!-- Toast 内容 -->
			<div class="toast" :class="type">
				<div class="content">
					<i class="iconfont" :class="'icon-' + type"></i>
					<span>{{ content }}</span>
				</div>
				<!-- 关闭按钮 -->
				<i v-if="hasClose" class="el-icon-close close" @click="visible = false"></i>
			</div>
		</div>
	</transition>
</template>
 
<script>
	export default {
		name: 'toast-tip',
		data() {
			return {
				content: '',
				time: 3000,
				visible: false,
				//对应的动态显示class type四种类型：info, success, warning, error
				type: 'error',
				hasClose: true,
			}
		},
		mounted() {
			this.close();
		},
		methods: {
			close() {
				setTimeout(() => {
					this.visible = false;
				}, this.time);
			}
		}
	}
</script>
 
<style lang="scss" scoped>
	/* 动画效果 淡入淡出 */
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s ease;
	}
 
	.fade-enter,
	.fade-leave-active {
		opacity: 0;
	}
 
	.toast-container {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		// 横居中
		justify-content: center;
		// 竖居中
		align-items: center;
		z-index: 99999;
 
		.toast {
			width: 340px;
			padding: 20px;
			border-radius: 6px;
			font-size: 16px;
			display: flex;
			justify-content: space-between;
			align-items: center;
 
			.content {
				span {
					padding-left: 10px;
				}
			}
			// 不同类型下的Toast 的显示样式
			&.info {
				background: #edf2fc;
				border: 1px solid #e0eafc;
				color: #909399;
			}
 
			&.success {
				background: #f0f9eb;
				border: 1px solid #e7f9de;
				color: #67c23a;
			}
 
			&.warning {
				background: #fdf6ec;
				border: 1px solid #f9ecda;
				color: #e6a23c;
			}
 
			&.error {
				background: #fef0f0;
				border: 1px solid #fbdfdf;
				color: #f56c6c;
			}
			
			//关闭按钮的样式
			.close {
				cursor: pointer;
				color: #909399;
			}
			
		}
	}
</style>